<template>
  <div>

    <div v-if="progressStore.progress !== true">

      <!-- Add Provider Dialog Box -->
      <v-dialog v-model="addProviderDialog" persistent max-width="500px">
        <v-card>
          <v-card-title class="text-h5 secondary--text">Add As Provider?<v-spacer /><v-btn text rounded outlined @click="closeAddProviderDialog()"><v-icon
            small left>fal fa-times</v-icon>Cancel</v-btn></v-card-title>
          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12" sm="12">
                  {{facilitiesStore.facility.ffacname}} will be added as a provider.
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn block rounded color="primary" @click.native="addProvider()">
              <v-icon small left>fal fa-plus</v-icon>Add</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!--Title Bar-->
      <v-container class="py-2" fluid>
        <v-row dense>
          <v-col cols="12" sm="12">
            <div class="text-h4 secondary--text">{{facilitiesStore.facility.ffacid}}
              - {{facilitiesStore.facility.ffacname}}</div>
          </v-col>
        </v-row>
      </v-container>

      <v-toolbar flat dense>

        <record-navigation
          :show="facilitiesStore.facilities.data.length > 0"
          :section="1"
          :currentIndex="facilitiesStore.currentIndex"
          :lastRecord="facilitiesStore.facilities.total"
          :from="facilitiesStore.facilities.from"
          :to="facilitiesStore.facilities.to"
        />

        <v-spacer />

        <v-toolbar-items>
          <!--Add or Go To Provider-->
          <v-tooltip top :color="facilitiesStore.facility.provider ? 'green' :'primary'">
            <template #activator="{ on }">
              <v-btn icon v-on="on" @click="provider()">
                <v-icon
                  :color="facilitiesStore.facility.provider ? 'green' :'primary'"
                >
                  fal fa-school
                </v-icon>
              </v-btn>
            </template>
            <span>{{facilitiesStore.facility.provider ? 'Go Provider' :'Add Provider'}}</span>
          </v-tooltip>
        </v-toolbar-items>
      </v-toolbar>

      <v-container fluid class="pl-0">

        <v-row dense>

          <!--Facility Details-->
          <v-col cols="12" md="8">
            <v-card flat>
              <v-toolbar dense flat color="secondary" dark>
                <v-icon>fal fa-hotel</v-icon>
                <v-toolbar-title class="pl-2">DCDEE Facility Details</v-toolbar-title>
              </v-toolbar>
            </v-card>

            <v-card flat class="blue-grey lighten-5" height="100%">
              <v-card-text>

                <v-row dense>
                  <v-col cols="12" md="10">
                    <text-display :displayLabel="'Facility Name'" :displayString="facilitiesStore.facility.ffacname" />
                  </v-col>
                  <v-col cols="12" md="2">
                    <text-display :displayLabel="'Status'" :displayString="facilitiesStore.facility.fstatus" />
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col sm="6" md="2">
                    <text-display :displayLabel="'License #'" :displayString="facilitiesStore.facility.ffacid" />
                  </v-col>
                  <v-col sm="6" md="2">
                    <text-display :displayLabel="'Operation Type'" :displayString="facilitiesStore.facility.foptype" />
                  </v-col>
                  <v-col sm="8" md="6">
                    <text-display :displayLabel="'Sponsor'" :displayString="facilitiesStore.facility.fsponsor" />
                  </v-col>
                  <v-col sm="4" md="2">
                    <text-display :displayLabel="'County'" :displayString="facilitiesStore.facility.county.d_name" />
                  </v-col>
                </v-row>

                <v-row dense class="py-2">
                  <v-divider light></v-divider>
                </v-row>

                <v-row dense>
                  <v-col cols="12" md="6">

                    <v-row dense>
                      <v-col cols="12">
                        <text-display :displayLabel="'Location Address'" :displayString="facilitiesStore.facility.flstreet" />
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col sm="6">
                        <text-display :displayLabel="'City'" :displayString="facilitiesStore.facility.flcity" />
                      </v-col>
                      <v-col sm="3">
                        <text-display :displayLabel="'State'" :displayString="facilitiesStore.facility.flstate" />
                      </v-col>
                      <v-col sm="3">
                        <text-display :displayLabel="'Zip'" :displayString="facilitiesStore.facility.flzip" />
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-row dense>
                      <v-col cols="12">
                        <text-display :displayLabel="'Mailing Address'" :displayString="facilitiesStore.facility.fstreet" />
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col sm="6">
                        <text-display :displayLabel="'City'" :displayString="facilitiesStore.facility.fcity" />
                      </v-col>
                      <v-col sm="3">
                        <text-display :displayLabel="'State'" :displayString="facilitiesStore.facility.fstate" />
                      </v-col>
                      <v-col sm="3">
                        <text-display :displayLabel="'Zip'" :displayString="facilitiesStore.facility.fzip" />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col sm="6" md="3">
                    <text-display :displayLabel="'Phone'" :displayString="facilitiesStore.facility.fphone" />
                  </v-col>
                  <v-col sm="6" md="3">
                    <text-display :displayLabel="'Fax'" :displayString="facilitiesStore.facility.ffax" />
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" md="6">
                    <text-display :displayLabel="'Email'" :displayString="facilitiesStore.facility.femail" />
                  </v-col>
                  <v-col cols="12" md="6">
                    <text-display :displayLabel="'Website'" :displayString="facilitiesStore.facility.fweb" />
                  </v-col>
                </v-row>

                <v-row dense class="py-2">
                  <v-divider light></v-divider>
                </v-row>

                <v-row dense>
                  <v-col cols="12" md="2">
                    <v-col cols="12">
                      <text-display :displayLabel="'Infant'" :displayNumber="facilitiesStore.facility.f0y1" />
                    </v-col>
                    <v-col cols="12">
                      <text-display :displayLabel="'1 Year'" :displayNumber="facilitiesStore.facility.f1y1" />
                    </v-col>
                    <v-col cols="12">
                      <text-display :displayLabel="'2 year'" :displayNumber="facilitiesStore.facility.f2y1" />
                    </v-col>
                    <v-col cols="12">
                      <text-display :displayLabel="'3 year'" :displayNumber="facilitiesStore.facility.f3y1" />
                    </v-col>
                  </v-col>

                  <v-col cols="12" md="2">
                    <v-col cols="12">
                      <text-display :displayLabel="'4 Year'" :displayNumber="facilitiesStore.facility.f4y1" />
                    </v-col>
                    <v-col cols="12">
                      <text-display :displayLabel="'5 Year'" :displayNumber="facilitiesStore.facility.f5y1" />
                    </v-col>
                    <v-col cols="12">
                      <text-display :displayLabel="'5 Year/School Age'" :displayNumber="facilitiesStore.facility.f5yps1" />
                    </v-col>
                  </v-col>

                  <v-col cols="12" md="2">
                    <v-col cols="12">
                      <text-display :displayLabel="'Total Enrolled'" :displayNumber="facilitiesStore.facility.totalenrolled" />
                    </v-col>
                    <v-col cols="12">
                      <text-display :displayLabel="'Care Givers'" :displayNumber="facilitiesStore.facility.fcareno" />
                    </v-col>
                    <v-col cols="12">
                      <text-display :displayLabel="'# Admin Staff'" :displayNumber="facilitiesStore.facility.fadminno" />
                    </v-col>
                  </v-col>
                </v-row>

              </v-card-text>
            </v-card>
          </v-col>

          <!--Rating Information-->
          <v-col cols="12" md="4">
            <v-card flat>
              <v-toolbar dense flat color="secondary" dark>
                <v-icon>fal fa-star</v-icon>
                <v-toolbar-title class="pl-2">Rating Information</v-toolbar-title>
              </v-toolbar>
            </v-card>

            <v-card flat class="blue-grey lighten-5">
              <v-card-text>
                <v-row dense>
                  <v-col cols="12">
                    <text-display :displayLabel="'Current Star Rating'" :displayString="facilitiesStore.facility.starrating" />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12">
                    <text-display :displayLabel="'Star Rated Component Type'" :displayString="facilitiesStore.facility.fstartype" />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12">
                    <text-display :displayLabel="'Existing 3 Component Star Rating Type'" :displayNumber="facilitiesStore.facility.fstartype3 !== 0 ? facilitiesStore.facility.fstartype3 : '' " />
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12">
                    <v-list two-line class="blue-grey lighten-5">
                      <template v-for="(item, index) in facilitiesStore.facility.j_history">
                        <v-list-item
                          class="my-1"
                          :key=index
                        >
                          <v-list-item-content>
                            <v-list-item-title>{{item
                              .date}}</v-list-item-title>
                            <v-list-item-title>{{item
                              .comment}}</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-rating
                              v-if="item.rating === '1 STAR' || item.rating === '2 STAR' || item.rating
                 === '3 STAR' || item.rating === '4 STAR' || item.rating === '5 STAR'"
                              :value="$_switchRating(item.rating)"
                              color="yellow darken-2"
                              readonly
                              small
                              background-color="grey lighten-1">
                            </v-rating>
                            <span v-else>{{item.rating}}</span>
                          </v-list-item-action>
                        </v-list-item>
                        <v-divider />
                      </template>
                    </v-list>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mixin } from '@/mixins/mixin.js';
import Message from '@/components/Message';
import TextDisplay from '@/components/TextDisplay';
import RecordNavigation from '@/components/RecordNavigation';

export default {
  name: 'Facility',
  mixins: [mixin],
  computed: {
    ...mapState({
      facilitiesStore: (state) => state.facilitiesStore,
      progressStore: (state) => state.progressStore,
    }),
  },
  components: {
    Message,
    TextDisplay,
    RecordNavigation,
  },
  created() {
    this.initialize();
  },
  data() {
    return {
	    addProviderDialog: false,
    };
  },
  methods: {
    async initialize() {
      this.$store.dispatch('progressStore/set', true);
      await this.$store.dispatch('facilitiesStore/read', this.$route.params.facilityUUID);
      this.$store.dispatch('progressStore/set', false);
    },

    provider() {
	    const facility = this.$store.getters['facilitiesStore/getFacility'];
	    /* If provider already associated */
	    if (facility.provider) {
	    	const { uuid } = facility.provider;
		    this.$router.push({ name: 'provider', params: { providerUUID: uuid } });
	    }
	    /* No provider associated */
	    else {
	    	/* If facility status is terminated, show warning */
	    	if (facility.fstatus === 'Terminated') {
	    		const data = {
	    			color: 'red',
            text: 'This facility has a terminated status and can\'t be added as a provider.',
	    		};
			    this.$store.commit('messagesStore/setMessage', data);
        } else {
	    		this.openAddProviderDialog();
        }
      }
    },

	  openAddProviderDialog() {
		  this.addProviderDialog = true;
	  },

	  closeAddProviderDialog() {
		  this.addProviderDialog = false;
    },

    async addProvider() {
    	this.$store.dispatch('providersStore/clearProvider');
      const facility = this.$store.getters['facilitiesStore/getFacility'];

	    /* Build provider data */
	    const provider = {
	    	fk_facilityID: facility.id,
        fk_countyID: facility.fcounty,
        d_nameLicense: facility.ffacname,
        d_licenseNumber: facility.ffacid,
        fk_ratingID: facility.rating ? facility.rating.id : '',
        d_website: this.$_checkNull(facility.fweb),
        d_facilityStreet1: facility.flstreet,
        d_facilityCity: facility.flcity,
        d_facilityState: facility.flstate,
        d_facilityPostalCode: facility.flzip,
        f_type: facility.foptype === 'Center' ? 1 : 2,
        f_status: 1,
	    };
	    await this.$store.dispatch('providersStore/create', provider);
      const newProvider = this.$store.getters['providersStore/getProvider'];
      if (newProvider) {
      	const { uuid } = newProvider;
	      this.$router.push({ name: 'provider', params: { providerUUID: uuid } });
      }
    },

  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
